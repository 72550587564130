import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../services/utils.service';

export const contentHashInterceptor: HttpInterceptorFn = (req, next) => {

  const auth = inject(AuthService);
  const utils = inject(UtilsService);

  let headers: any = {
    'Content-Hash': environment.contentHash,
    'X-Date': utils.dateToYmd(new Date()),
  }
  if (auth.checkSession()) {
    headers['X-SID'] = auth.user?.session?.sid || '';
  }

  // Clone the request and add the authorization header
  const authReq = req.clone({
    setHeaders: headers
  });

  // Pass the cloned request with the updated header to the next handler
  return next(authReq);
};
